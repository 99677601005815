<template>
  <b-container class="mt-4">
    <b-form @submit="onSubmit">
      <b-card header-class="header">
        <template #header>
          <label v-text="'Personel oluştur'" class="mr-2 text-white"></label>
          <b-button variant="dark" class="mr-2" to="/personel"
            >Personeller</b-button
          >
          <b-button ref="submitButton" variant="primary" type="submit"
            >Gönder</b-button
          >
        </template>
        <b-row class="buttonBar" style="pointer-events: none" align-h="end">
          <b-col md="auto" style="pointer-events: auto">
            <b-button
              class="pageButton"
              type="button"
              :variant="currentTab === 0 ? 'light' : 'secondary'"
              :active="currentTab === 0"
              @click="currentTab = 0"
            >
              Nüfus Bilgileri
            </b-button>
            <b-button
              class="pageButton ml-2"
              type="button"
              :variant="currentTab === 1 ? 'light' : 'secondary'"
              :active="currentTab === 1"
              @click="currentTab = 1"
            >
              Branş Bilgileri
            </b-button>
            <b-button
              class="pageButton ml-2"
              type="button"
              :variant="currentTab === 2 ? 'light' : 'secondary'"
              :active="currentTab === 2"
              @click="currentTab = 2"
            >
              İletişim Bilgileri
            </b-button>
            <b-button
              class="pageButton ml-2"
              type="button"
              :variant="currentTab === 3 ? 'light' : 'secondary'"
              :active="currentTab === 3"
              @click="currentTab = 3"
            >
              Öğrenim Bilgileri
            </b-button>
            <b-button
              class="pageButton ml-2"
              type="button"
              :variant="currentTab === 4 ? 'light' : 'secondary'"
              :active="currentTab === 4"
              @click="currentTab = 4"
            >
              Sistem Bilgileri
            </b-button>
          </b-col>
        </b-row>
        <b-alert class="spacing" show>
          Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
        </b-alert>

        <div v-if="currentTab === 0" class="mt-4">
          <PersonalForm :form="form" :selectedPicture="selectedPicture" />
        </div>
        <div v-else-if="currentTab === 1" class="mt-4">
          <BranchForm :form="form" />
        </div>
        <div v-else-if="currentTab === 2" class="mt-4">
          <ContactForm :form="form" />
        </div>
        <div v-else-if="currentTab === 3" class="mt-4">
          <EducationForm :form="form" />
        </div>
        <div v-else-if="currentTab === 4" class="mt-4">
          <SystemForm :form="form" />
        </div>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import BranchForm from "@/components/forms/personel/BranchForm.vue";
import ContactForm from "@/components/forms/personel/ContactForm.vue";
import EducationForm from "@/components/forms/personel/EducationForm.vue";
import PersonalForm from "@/components/forms/personel/PersonalForm.vue";
import SystemForm from "@/components/forms/personel/SystemForm.vue";
export default {
  components: {
    BranchForm,
    ContactForm,
    EducationForm,
    PersonalForm,
    SystemForm,
  },
  data() {
    return {
      selectedPicture: {
        file: null,
        fileName: "Dosya seçin veya buraya sürükleyin...",
      },
      form: {
        ssn: "", //Baskan
        surname: "", //Kurulus tarihi
        name: "", //Kapanis tarihi
        gender: "", //Adres
        birthDate: "", //Deniz kanosu son akredite yili
        photoAddress: "", //Takim fotograf adresi
        //Branch Information
        branch: {
          clubId: 0, //Adi
          secondClubId: 0, //Adi
          size: "", //Baskan
          weight: "", //Bolge
          bodySize: "", //Tescil No
          shoeSize: "", //Kapanis tarihi
          sportsShoeSize: "", //Adres
          branch: "", //Telefon
          category: "", //Akarsu son akredite yili
          boatClass: "", //Durgunsu son akredite yili
          sportState: "", //Deniz kanosu son akredite yili
          sportLicenceNumber: "", //Dragon bot son akredite yili
          sportLastLicenseDate: 0, //Faks
          sportRefereeState: "", //E posta adresi
          sportRefereeLastLicenseDate: 0, //Web adresi
          stillWaterCandidateRefereePromotionDate: "", //Resim adresi
          stillWaterDistrictRefereePromotionDate: "", //Takim fotograf adresi
          stillWaterNationalRefereePromotionDate: "", //Aciklama
          stillWaterInternationalRefereePromotionDate: "", //Sifre
          runningWaterCandidateRefereePromotionDate: "", //Faks
          runningWaterDistrictRefereePromotionDate: "", //E posta adresi
          runningWaterNationalRefereePromotionDate: "", //Web adresi
          runningWaterInternationalRefereePromotionDate: "", //Resim adresi
          trainerState: "", //Takim fotograf adresi
          trainerLastLicenseDate: 0, //Aciklama
          stillWaterAssistantTrainerPromotionDate: "", //Sifre
          stillWaterTrainerPromotionDate: "", //Adi
          stillWaterSeniorTrainerPromotionDate: "", //Baskan
          stillWaterHeadTrainerPromotionDate: "", //Bolge
          stillWaterCoachPromotionDate: "", //Tescil No
          runningWaterTrainerPromotionDate: "", //Kurulus tarihi
          runningWaterAssistantTrainerPromotionDate: "", //Kapanis tarihi
          runningWaterSeniorTrainerPromotionDate: "", //Adres
          runningWaterHeadTrainerPromotionDate: "", //Telefon
          runningWaterCoachPromotionDate: "", //Akarsu son akredite yili
          provincialRepresentative: "", //Durgunsu son akredite yili
          assemblyMember: "", //Deniz kanosu son akredite yili
          assembly: "", //Dragon bot son akredite yili
          personel: "", //Faks
          personelDuty: "", //E posta adresi
          manager: "", //Web adresi
          managerDuty: "", //Resim adresi
          description: "", //Takim fotograf adresi
        },
        //Contact Information
        contact: {
          workPhoneNumber: "", //Adi
          workAddress: "", //Baskan
          homePhoneNumber: "", //Bolge
          homeAdress: "", //Tescil No
          cityWhereLocated: "", //Kapanis tarihi
          districtWhereLocated: "", //Kurulus tarihi
          gsm: "", //Adres
          email: "", //Telefon
          website: "", //Akarsu son akredite yili
          bankInfo: "", //Durgunsu son akredite yili
          message: false, //Deniz kanosu son akredite yili
        },
        //Education Information
        education: {
          educationStatus: "", //Kapanis tarihi
          schoolDepartmentNo: "", //Kurulus tarihi
          lastSchoolAttended: "", //Adres
          foreignLanguage: "", //Telefon
        },
        //System Information
        system: {
          password: "", //Adres
          approval: 0, //Telefon
        },
      },
      currentTab: 0,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.selectedPicture.file) {
        this.form.changepictureAddress = this.form.pictureAddress;
        this.form.pictureAddress = this.selectedPicture.file;
      }
      this.$store
        .dispatch("savePersonel", this.form)
        .then(() => this.$router.push("/pending-approvals"));
    },
    checkFormValidity() {
      if (
        ![
          this.form.ssn,
          this.form.surname,
          this.form.name,
          this.form.gender,
        ].every((e) => e)
      ) {
        this.currentTab = 0;
        setTimeout(() => this.$refs.submitButton.click(), 0);
        return false;
      } else if (
        ![this.form.contact.cityWhereLocated].every(
          (e) => e
        )
      ) {
        this.currentTab = 2;
        setTimeout(() => this.$refs.submitButton.click(), 0);
        return false;
      } else if (![this.form.system.password].every((e) => e)) {
        this.currentTab = 4;
        setTimeout(() => this.$refs.submitButton.click(), 0);
        return false;
      }
      return true;
    },
    getColor(color) {
      if (this.currentCard == color) {
        return "color:white";
      } else {
        return "color:black";
      }
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.selection-field {
  max-width: 16rem;
  min-width: 16rem;
}
.align-right {
  margin-right: 1rem;
}
.tab {
  color: white;
  background: gray;
}
.pageButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.buttonBar {
  margin-top: -3.65rem;
}
.spacing {
  margin-top: 1.5rem;
}
.dark {
  color: black;
}
</style>